/*=========================================================================================
  File Name: moduleAnnualPlanMutations.js
  Description: AnnualPlan Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.annualPlans.unshift(item)
  },
  SET_AnnualPlans(state, annualPlans) {
    state.annualPlans = annualPlans
  },
  SET_AnnualPlanList(state, annualPlans) {
   
    state.annualPlanList = annualPlans
  },
  UPDATE_AnnualPlan(state, annualPlan) {
   
    var annualPlanIndex = state.annualPlans.findIndex((p) => p.ID == annualPlan.ID)
    if(annualPlanIndex!=-1)
    Object.assign(state.annualPlans[annualPlanIndex], annualPlan)
     annualPlanIndex = state.annualPlanList.findIndex((p) => p.ID == annualPlan.ID)
     if(annualPlanIndex!=-1)
    Object.assign(state.annualPlanList[annualPlanIndex], annualPlan)
},
  REMOVE_ITEM(state, itemId) {
      const ItemIndex = state.annualPlans.findIndex((p) => p.ID == itemId)
      state.annualPlans.splice(ItemIndex, 1)
  },

  SET_InstructorSchedule(state, instructorSchedule) {
    state.instructorSchedule = instructorSchedule
  },

  SET_AnnualPlanInstructor(state, AnnualPlanInstructors) {
    state.AnnualPlanInstructors = AnnualPlanInstructors
  },
  ADD_AnnualPlanInstructor(state, item) {
    state.AnnualPlanInstructors.unshift(item)
  },
  REMOVE_AnnualPlanInstructor(state, index) {
    state.AnnualPlanInstructors.splice(index, 1)
   },
}
