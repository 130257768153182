<template>
  <div>
    <div class="vx-row">
      <feather-icon icon="ChevronLeftIcon" style="width: 30px" @click="back()">
      </feather-icon>
      <p class="text-xl">
        {{ $t("DoctorTitleAvail") + doctorModel.Name }}
      </p>
    </div>
    <br />
    <h5 class="pl-5 pr-5" style="color: orange">
      {{ $t("EditAvailHant") }}
    </h5>

    <div class="flex items-center justify-center inset-0 ...">
      <div class="vx-row w-full">
        <div class="vx-col w-ful lg:w-1/3">
          <!-- price card-->
          <vx-card class="vx-row m-3">
       

            <p style="font-weight: bold; color: #454a62">
              {{ $t("Online Consultation Latest Price") }}
            </p>

            <div class="vx-row pl-4 pr-4">
              <vs-input type="number" v-model="priceModel.Price" class="mt-3" />
              <!-- <p class="mt-2" style="font-size:30px">$</p> -->
              <v-select
                class="mt-3 pl-2 pr-2"
                label="Name"
                v-model="priceModel.CurrencyID"
                :options="currencies"
                :placeholder="$t('Currency')"
                :reduce="(obj) => obj.ID"
              />
            </div>
          </vx-card>
          <!-- Date card -->
          <vx-card class="vx-row m-3">
            <div class="vx-col">
              <div class="vx-row">
                <p style="font-weight: bold; color: #454a62" class="m-3">
                  {{ $t("Start Date") }}
                </p>
                <datepicker
                  :placeholder="$t('Start Date')"
                  v-model="model.DateFrom"
                 
                  name="DateFrom"
                ></datepicker>
              </div>
              <div class="vx-row mt-1 ">
                <p style="font-weight: bold; color: #454a62" class="m-3">
                  {{ $t("End Date") }}
                </p>
                <datepicker
                  :placeholder="$t('End Date')"
                  v-model="model.DateTo"
                  
                  name="DateTo"
                ></datepicker>
              </div>
               <!-- year -->
              <div class="vx-row mt-5">
                <div>
                  <p style="font-weight: bold; color: #454a62" class="m-3">
                    {{ $t("ChooseYear") }}*
                  </p>
                </div>
                <template>
                  <datepicker
                    :minimumView="'year'"
                    v-model="model.Year"
                    placeholder="year"
                    format="yyyy"
                  ></datepicker>
                </template>
              </div>
            </div>
          </vx-card>
          <!-- days and year card -->
          <vx-card class="vx-row m-3">
            <div class="vx-col">
              <!-- day  -->
              <div class="vx-col">
                <div>
                  <p style="font-weight: bold; color: #454a62" class="m-3">
                    {{ $t("Choose Days") }}
                  </p>
                </div>
                <div class="vx-row ml-2 mr-2">
                  <vs-button
                    size="small"
                    @click="model.Sunday = !model.Sunday"
                    class="m-1"
                    :color="model.Sunday ? '#009cff' : 'white'"
                    :style="[
                      model.Sunday ? {} : { border: ' 2px solid #1F1F1F' },
                    ]"
                    style="border-radius: 34px; opacity: 1; border-color: black"
                    ><span
                      :style="[
                        model.Sunday ? { color: 'white' } : { color: 'black' },
                      ]"
                    >
                      {{ $t("Sun") }}</span
                    >
                  </vs-button>
                  <vs-button
                    size="small"
                    @click="model.Monday = !model.Monday"
                    :color="model.Monday ? '#009cff' : 'white'"
                    :style="[
                      model.Monday ? {} : { border: ' 2px solid #1F1F1F' },
                    ]"
                    style="border-radius: 34px; opacity: 1"
                    class="m-1"
                    ><span
                      :style="[
                        model.Monday ? { color: 'white' } : { color: 'black' },
                      ]"
                    >
                      {{ $t("Mon") }}</span
                    >
                  </vs-button>
                  <vs-button
                    size="small"
                    @click="model.Tuesday = !model.Tuesday"
                    :color="model.Tuesday ? '#009cff' : 'white'"
                    :style="[
                      model.Tuesday ? {} : { border: ' 2px solid #1F1F1F' },
                    ]"
                    style="border-radius: 34px; opacity: 1"
                    class="m-1"
                    ><span
                      :style="[
                        model.Tuesday ? { color: 'white' } : { color: 'black' },
                      ]"
                    >
                      {{ $t("Tues") }}</span
                    >
                  </vs-button>
                  <vs-button
                    size="small"
                    @click="model.Wednesday = !model.Wednesday"
                    :color="model.Wednesday ? '#009cff' : 'white'"
                    :style="[
                      model.Wednesday ? {} : { border: ' 2px solid #1F1F1F' },
                    ]"
                    style="border-radius: 34px; opacity: 1"
                    class="m-1"
                    ><span
                      :style="[
                        model.Wednesday
                          ? { color: 'white' }
                          : { color: 'black' },
                      ]"
                    >
                      {{ $t("Wednes") }}</span
                    >
                  </vs-button>
                  <vs-button
                    size="small"
                    @click="model.Thursday = !model.Thursday"
                    :color="model.Thursday ? '#009cff' : 'white'"
                    :style="[
                      model.Thursday ? {} : { border: ' 2px solid #1F1F1F' },
                    ]"
                    style="border-radius: 34px; opacity: 1"
                    class="m-1"
                    ><span
                      :style="[
                        model.Thursday
                          ? { color: 'white' }
                          : { color: 'black' },
                      ]"
                    >
                      {{ $t("Thurs") }}</span
                    >
                  </vs-button>
                  <vs-button
                    size="small"
                    @click="model.Friday = !model.Friday"
                    :color="model.Friday ? '#009cff' : 'white'"
                    :style="[
                      model.Friday ? {} : { border: ' 2px solid #1F1F1F' },
                    ]"
                    style="border-radius: 34px; opacity: 1"
                    class="m-1"
                    ><span
                      :style="[
                        model.Friday ? { color: 'white' } : { color: 'black' },
                      ]"
                    >
                      {{ $t("Fri") }}</span
                    >
                  </vs-button>
                  <vs-button
                    size="small"
                    @click="model.Saturday = !model.Saturday"
                    :color="model.Saturday ? '#009cff' : 'white'"
                    :style="[
                      model.Saturday ? {} : { border: ' 2px solid #1F1F1F' },
                    ]"
                    style="border-radius: 34px; opacity: 1"
                    class="m-1"
                    ><span
                      :style="[
                        model.Saturday
                          ? { color: 'white' }
                          : { color: 'black' },
                      ]"
                    >
                      {{ $t("Sat") }}</span
                    >
                  </vs-button>
                </div>
              </div>
             
            </div>
          </vx-card>
          <!-- time card -->
          <vx-card class="vx-row m-3">
            <div class="vx-col">
              <div class="vx-row">
                <p style="font-weight: bold; color: #454a62" class="m-3">
                  {{ $t("From") }}
                </p>
                <vs-input type="time" v-model="model.TimeFrom"></vs-input>
              </div>
              <div class="vx-row mt-1 ml-20">
                <p style="font-weight: bold; color: #454a62" class="m-3">
                  {{ $t("To") }}
                </p>
                <vs-input type="time" v-model="model.TimeTo"></vs-input>
              </div>
            </div>
          </vx-card>
          <!-- buttons -->
          <div class="vx-col text-center">
            <!-- <div><u class="ml-20 mt-1" style="color:#454A62;font-weight:bold" @click="PreviewOnCalendar()" >{{$t('PreviewOnCalendar')}}</u></div> -->
            <div>
              <vs-button
                style="
                  border-radius: 34px;
                  opacity: 2;
                  height: 35px;
                  width: 50%;
                "
                @click="EditAvailability"
                     :disabled="!validSessionCalendar"
                class="mt-1"
                color="green"
                >{{ $t("Edit Availability") }}</vs-button
              >
            </div>
            <div>
              <vs-button
                style="
                  border-radius: 34px;
                  opacity: 2;
                  width: 50%;
                  height: 35px;
                "
                color="danger"
                @click="Block"
                     :disabled="!validSessionCalendar"
                class="mt-3"
                >{{ $t("Block") }}</vs-button
              >
            </div>
          </div>
        </div>
        <!-- calender -->
        <div class="vx-col w-ful lg:w-2/3">
          <vs-card class=" mt-3">

              <p
                class="mr-6"
                style="font-weight: bold; color: #454a62"
                v-if="DoctorSessions != null && DoctorSessions.length > 0"
              >
                {{ $t("DoctorPrice") }} :
                <label style="font-weight: normal">
                  {{
                    DoctorSessions[midOfDoctorSessionList].Price 
                  }}
                 <span v-if="DoctorSessions[midOfDoctorSessionList].Currency">{{  DoctorSessions[midOfDoctorSessionList].Currency.SymbolEN }}</span> 
                </label>
              
              </p>
            <FullCalendar
              defaultView="dayGridMonth"
              ref="fullCalendar"
              selectable="true"
              :disabled="true"
              :options="configOptions"
              editable="true"
              :height="1000"
              :width="800"
            />
          </vs-card>
        </div>
      </div>

      <vs-popup v-if="ShowBlockPopup" title="" :active.sync="ShowBlockPopup">
        <div class="vx-col mycard">
          <vx-card>
            <div slot="no-body">
              <div class="no-gutter justify-center items-center MyBG">
                <div class="align-center p-10">
                  <img
                    src="@/assets/images/pages/Doctor4me.png"
                    style="max-width: 250px"
                    alt="login"
                    class="mx-auto mt-3"
                  />
                  <div class="mt-10">
                    <p
                      style="
                        text-align: initial;
                        font: normal normal 200 20px/21px Futura PT;
                        letter-spacing: 0px;
                        color: #ffffff;
                      "
                    >
                      {{
                        $t(
                          "You are trying to block a date / time range with booked online appointments."
                        )
                      }}
                    </p>
                    <p
                      style="
                        text-align: initial;
                        font: normal normal 200 20px/21px Futura PT;
                        letter-spacing: 0px;
                        color: #ffffff;
                      "
                    >
                      {{
                        $t(
                          "Your request has been sent to your account manager to modify the appointments and release the requested date and time."
                        )
                      }}
                    </p>
                    <p>{{ this.BlockMessage }}</p>
                  </div>

                  <div
                    class="
                      flex flex-wrap
                      justify-center
                      flex-col-reverse
                      sm:flex-row
                    "
                  ></div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </vs-popup>
    </div>
  </div>
</template>
<script>
import { domain } from "@/gloabelConstant.js";
import "@fullcalendar/core/vdom"; // solve problem with Vite
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import moduleCurrency from "@/store/settings/currency/moduleCurrency.js";
import Datepicker from "vuejs-datepicker";
import listPlugin from "@fullcalendar/list";
import moduleAvailabilitySchedule from "@/store/AvailabilitySchedule/moduleAvailabilitySchedule.js";
export default {
  data() {
    return {
      priceModel: "",
      ShowBlockPopup: false,
      baseURL: domain,
      calendarPlugins: [
        dayGridPlugin,
        interactionPlugin,
        // listPlugin,
        timeGridPlugin,
      ],
      SearchModel: { DoctorID: this.$route.params.ID, SetPrice: true },
      doctorModel: {},
      BlockMessage: "",
      DoctorSessions: [],
      midOfDoctorSessionList: 0,
      calendarStart: null,
      model: {
        Sunday: false,
        Saturday: false,
        Friday: false,
        Thursday: false,
        Wednesday: false,
        Tuesday: false,
        Monday: false,
        Months: [],
      },
    };
  },
  components: {
    FullCalendar,
    dayGridPlugin,

    interactionPlugin,
    timeGridPlugin,
    Datepicker,
  },
  computed: {
       clearData()
    {
          this.model= {
        Sunday: false,
        Saturday: false,
        Friday: false,
        Thursday: false,
        Wednesday: false,
        Tuesday: false,
        Monday: false,
        Months: [],
            }
    },
     validSessionCalendar() {
      return (
        this.model.DateTo != undefined &&
        this.model.DateFrom != undefined &&
       this. model.Year
      );
    },
    configOptions() {
      return {
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        events: this.DoctorSessions,
        weekends: this.weekendsVisible,
        datesSet: this.handleMonthChange,
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        },
        initialView: "dayGridMonth",
      };
    },
    currencies() {
      return this.$store.state.CurrencyList.currencies;
    },
  },
  methods: {
     
    back() {
      this.$router.go(-1);
    },
    formatJsDate(dateToFormat) {
      debugger;
      return (
        dateToFormat.getFullYear() +
        "-" +
        (dateToFormat.getMonth() + 1) +
        "-" +
        dateToFormat.getDate()
      );
    },
    handleMonthChange(arg) {
      if (this.calendarStart != arg.startStr) {
        this.calendarStart = arg.startStr;
        this.SearchModel.DateFrom = this.formatJsDate(arg.view.activeStart);
        this.SearchModel.DateTo = this.formatJsDate(arg.view.activeEnd);
        this.SimpleSearchDoctorSessions(this.SearchModel);
      }
    },
    EditAvailability() {
      debugger;
      this.SaveDoctorSessionPrice();
      this.$vs.loading();
      this.model.DoctorID = this.$route.params.ID;

      this.model.Price = this.priceModel.Price;
      this.model.CurrencyID = this.priceModel.CurrencyID;
      debugger;
      this.$store
        .dispatch("DoctorList/EditAvailability", this.model)
        .then((res) => {
          if (res.status == 200) {
            this.SimpleSearchDoctorSessions(this.SearchModel);
          
          this.clearData();
          }
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    Block() {
      this.$vs.loading();
      this.model.DoctorID = this.$route.params.ID;
      this.$store
        .dispatch("DoctorList/BlockAvailability", this.model)
        .then((res) => {
          if (res.status == 200) {
            this.BlockMessage = res.data.Message;
            if (res.data.Data.length > 0) this.ShowBlockPopup = true;
            else {
              this.$vs.notify({
                title: "successfully",
                text: res.data.Message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "success",
              });
            }
            
         this.clearData();
            this.SimpleSearchDoctorSessions(this.SearchModel);
          }
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
     SaveDoctorSessionPrice() {
      this.$store
        .dispatch("DoctorList/SaveDoctorSessionPrice", this.priceModel)
        .then((res) => {
          if (res.status == 200) {
            this.$vs.loading.close();
          }
        })
        .catch(() => {
          this.$vs.loading.close();

          window.showError();
        });
    },
  SimpleSearchDoctorSessions(model) {
      this.DoctorSessions = [];
      this.$vs.loading();
      this.$store
        .dispatch("DoctorList/SimpleSearchDoctorSessions", model)
        .then((res) => {
          this.DoctorSessions = res.data.Data;
             debugger;
          this.midOfDoctorSessionList = this.DoctorSessions.length;
           if(this.DoctorSessions.length==1){
             this.midOfDoctorSessionList = 0;
           }
         else if (this.DoctorSessions.length % 2 == 0) {
            this.midOfDoctorSessionList = this.midOfDoctorSessionList / 2;
          } else {
            this.midOfDoctorSessionList = (this.midOfDoctorSessionList + 1) / 2;
          }
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
   
    Save() {},
    getSesionPrice() {
      this.$vs.loading();

      this.$store
        .dispatch(
          "DoctorList/GetDoctorSessionPriceByDoctorID",
          this.$route.params.ID
        )
        .then((res) => {
          if (res.status == 200) {
            this.priceModel = res.data.Data;
            this.$vs.loading.close();
          }
        })
        .catch(() => {
          this.$vs.loading.close();

          window.showError();
        });
    },
  },
  created() {
    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
    if (!moduleCurrency.isRegistered) {
      this.$store.registerModule("CurrencyList", moduleCurrency);
      moduleCurrency.isRegistered = true;
    }

    this.$store.dispatch("CurrencyList/GetAllCurrencies");

    // this.getDoctorSessions(this.SearchModel);
    const ID = this.$route.params.ID;
    if (ID != undefined) {
      this.$store.dispatch("DoctorList/GetDoctor", ID).then((res) => {
        this.doctorModel = res.data.Data;
        this.getSesionPrice();
      });
    }
  },
   mounted() {
    var vm = this;

    vm.$refs.fullCalendar.getApi().next();
         
            
    setTimeout(function () {
       //if(vm.DoctorSessions==null||vm.DoctorSessions.length==0)
        {
               vm.$refs.fullCalendar.getApi().prev();
        }
    }, 500);
  },
};
</script>
<style >
.con-vs-popup .vs-popup {
  box-shadow: unset;
}
</style>
<style scoped>
.MyBG {
  background: transparent
    radial-gradient(closest-side at 50% 50%, #5dbeff 0%, #2f5f80 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: -3px 0px 22px #00000029;
  border-radius: 31px 31px 31px 31px;
  opacity: 1;
}
.vx-card {
  /* background-color: transparent !important; */
  /* -webkit-box-shadow: unset; */
  /* box-shadow: unset; */
}

.vs-popup--content {
  box-shadow: unset;
}
</style>
<style>
</style>
