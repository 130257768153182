/*=========================================================================================
  File Name: moduleAnnualPlanActions.js
  Description: AnnualPlan Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";

export default {
  SearchAnnualPlan({ commit }, search) {
    return new Promise((resolve, reject) => {
      axios
      axios.post("api/DoctorSession/SearchDoctorSessions", search)
        .then(response => {
          commit("SET_AnnualPlanList", response.data.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
