/*=========================================================================================
  File Name: moduleAnnualPlan.js
  Description: AnnualPlan Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import state from "./moduleAvailabilityScheduleState.js";
import mutations from "./moduleAvailabilityScheduleMutations.js";
import actions from "./moduleAvailabilityScheduleActions.js";
import getters from "./moduleAvailabilityScheduleGetters.js";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
